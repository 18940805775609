<template>
  <div>
    <div class="padding" v-if="$route.query.from == 'mine'">
      <el-page-header :title="$t('返回')" @back="goBack" :content="$t('学生认证')">
      </el-page-header>
    </div>
    <el-card class="header">
      <div slot="header" class="flex flex-v-center header">
        <h3>{{ $t($store.state.mode + '学生认证') }}</h3>


        <h4 v-if="$store.getters.getCertificateStatus() == 1" class="color-white margin-x">({{ $t('已认证') }})</h4>
        <h4 v-else-if="$store.getters.getCertificateStatus() == 0" class="color-white margin-x">({{ $t('认证中') }})</h4>
        <h4 v-else class="color-white margin-x">({{ $t('未认证') }})</h4>

      </div>


      <el-form label-position="top" ref="form" size="small" :model="form" label-width="100px">

        <el-form-item prop="name" :label="$t('姓名') + ':'"
          :rules="[{ required: true, message: $t('请填写姓名'), trigger: 'blur' },]">
          <el-input v-model="form.name" :placeholder="`* ${$t('中文汉字姓名')}`"></el-input>
        </el-form-item>


        <el-form-item prop="phone" :label="$t('电话') + ':'"
          :rules="[{ required: true, message: $t('请填写电话'), trigger: 'blur' },]">
          <el-input v-model="form.phone"></el-input>
        </el-form-item>


        <el-form-item prop="wechat" :label="$t('微信号') + ' / Line Id' + ':'"
          :rules="[{ required: true, message: $t('请填写微信号') + '/ Line Id', trigger: 'blur' },]">
          <el-input v-model="form.wechat"></el-input>
        </el-form-item>

        <div class="margin-lt center" v-if="$store.getters.getCertificateStatus() != 1">
          <el-button v-if="$store.getters.getCertificateStatus() == -1" type="primary" round @click="submit">{{ $t('提交')
          }}</el-button>

          <el-button v-else-if="$store.getters.getCertificateStatus() == 0" type="danger" round @click="submit">{{
            $t('重新提交') }}</el-button>
        </div>

      </el-form>
    </el-card>



  </div>
</template>
<script>
export default {
  data() {
    return {
      form: { status: 0 },
    };
  },

  created() {
    if (this.$store.getters.getCertificate()) {
      this.form = this.$store.getters.getCertificate();
    }
  },

  methods: {
    goBack() {
      this.$router.back();
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$http.post("/api/user/certificate", this.form).then((user) => {
            this.dialogVisible = false;
            this.$store.commit("setUser", user);
            this.$message({
              message: this.$t('提交成功') + '!',
              type: 'success'
            });
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }
  },
};
</script>

<style scoped>
.header>>>.el-card__header {
  background: rgb(67, 69, 82);
  color: rgb(233, 207, 164);
}

.avatar {
  width: 100px;
  width: 100px;
  border-radius: 50%;
}

.ll {
  width: 120px;
  text-align: right;
}

.lr {
  width: 150px;
  text-align: left;
}

.avatar {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  background-color: #ccc;
}

.avatar-box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 100px;
  border-radius: 50%;
}

.avatar-box:hover::after {
  background-color: rgba(0, 0, 0, .5);
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  height: 100px;
  width: 100px;
  border-radius: 50%;
  font-size: 14px;
  content: "更换图像";
}
</style>
